import styles from "../styles/connect.module.css";
import { FaRegHandshake } from "react-icons/fa";
import { useState } from "react";

const Connect = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [result, setResult] = useState("");

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setResult("Please wait...");

    fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        ...formData,
        access_key: "9bb6b522-ae59-444e-be95-f9f3788bc47c"
      })
    })
      .then(async (response) => {
        let json = await response.json();
        if (response.status === 200) {
          setResult("Form submitted successfully!");
        } else {
          setResult(json.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setResult("Something went wrong!");
      })
      .then(() => {
        setFormData({ name: "", email: "", message: "" });
        setTimeout(() => {
          setResult("");
        }, 3000);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p className={styles.topic}>Send me a message!</p>
        <p className={styles.subheading}>
          Got a question or proposal, or just want to say hello? Go ahead.
        </p>

        {/* Form with POST functionality */}
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.row}>
            <div>
              <label>Your Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div>
              <label>Email Address</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
          </div>

          <div className={styles.column}>
            <label>Your Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Enter your message"
              required
              rows = {5}
            />
          </div>


          <button type="submit" className={styles.connect}>
            Connect{" "}
            <FaRegHandshake
              color="#63fbd7"
              style={{ transform: "scale(1.2)", marginLeft: "20px" }}
            />
          </button>
        </form>

        {/* Display result message */}
        <div id="result" className={styles.result}>
          {result}
        </div>
      </div>
    </div>
  );
};

export default Connect;
