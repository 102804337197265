import { useContext, useState } from "react";
import styles from "../styles/header.module.css";
import { IoMdCloudDownload } from "react-icons/io";
import { CursorContext } from "./cursorContext";
import data from "../data/template.json";

const Header = () => {
  const names = ["MRxTHUNDER", "Reactjs", "HTML/CSS", "Flutter", "Spring"];

  const [tags, setTags] = useState("MRxTHUNDER");
  setInterval(() => {
    const index = Math.floor(Math.random() * names.length);
    setTags(names[index]);
  }, 3000);

  const [cursor, setCursor] = useContext(CursorContext);
  const [sideBar, setSideBar] = useState(false);

  const resumeDownloadLink = data.resumePdfUrl.replace(
    "/view?usp=sharing",
    "/uc?export=download"
  ); // Convert Google Drive link to direct download format

  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <div className={styles.roller}>{"MRxTHUNDER"}</div>
        <div className={styles.menuButton}>
          <a
            href={resumeDownloadLink}  // Updated download link
            className={styles.download}
            onMouseOver={() => {
              setCursor({ active: true });
            }}
            onMouseLeave={() => {
              setCursor({ active: false });
            }}
            download="resume_sahil.pdf"
          >
            Download Resume
            <IoMdCloudDownload
              style={{ transform: "scale(1.5)", marginLeft: "5px" }}
            />
          </a>

          <div
            className={styles.menuBar}
            onClick={() => {
              setSideBar(!sideBar);
            }}
          >
            <span
              className={`${styles.top} ${sideBar ? styles.clockwise : null}`}
            ></span>
            <span
              className={`${styles.middle} ${sideBar ? styles.hide : null}`}
            ></span>
            <span
              className={`${styles.bottom} ${
                sideBar ? styles.antiClockwise : null
              }`}
            ></span>
          </div>
        </div>
      </div>
      <div
        className={`${styles.sideBarScreen} ${
          sideBar ? styles.sideBarActive : null
        }`}
      ></div>
    </div>
  );
};
export default Header;
